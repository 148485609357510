<template>
  <div class="content">

    <!--        标题-->
    <commonTitle />
    <div v-show="actives == 0">
      <el-steps :active="active" finish-status="success" align-center>
        <el-step title="项目基础信息">
        </el-step>
        <el-step title="预分摊信息预览">
        </el-step>
        <el-step title="发起投票">
        </el-step>
        <el-step title="提交审核">
        </el-step>
        <el-step title="拨款申请">
        </el-step>
        <el-step title="拨款通知单">
        </el-step>
      </el-steps>

      <!-- 第一步项目基础信息-->
      <div v-if="active==0" style="margin-top: 50px;">
        <el-form :model="ruleFormRepair" :rules="rules1" ref="ruleFormRepair" label-position="left"
                 label-width="100px"
                 class="demo-ruleForm"
                 :element-loading-text="loadingText1"
                 v-loading="loading">
          <el-row>
            <el-col>
              <el-form-item class="itemlabel">
                <span style="color: #999; position: absolute; left: -11.2%;">基础信息</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-top: 30px;">
            <el-col :span="12">
              <el-form-item label="隶属房管机构">
                <el-input v-model="ruleFormRepair.org" placeholder="请输入维修项目名称" size="small"
                          style="width: 210px;" readonly/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="维修小区名称" prop="village" label-width="105px">
                <el-select v-model="ruleFormRepair.village" placeholder="请选择小区" size="small"
                           style="width: 210px;">
                  <el-option v-for="item in repairVillage" :key="item.villageId" :label="item.villageName"
                             :value="item.villageId">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item class="itemlabel">
                <span style="color: #999; position: absolute; left: -11.2%;">维修信息</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="margin-top: 30px;">
            <el-col :span="12">
              <el-form-item label="使用类型" prop="type">
                <el-select v-model="ruleFormRepair.type" style="width: 210px;" size="small"
                           placeholder="请选择使用类型" @change="selectValue">
                  <el-option v-for="item in repairList" :key="item.id" :label="item.label"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="项目名称" prop="name" label-width="105px">
                <el-input v-model="ruleFormRepair.name" size="small" style="width: 210px;"
                          placeholder="请输入项目名称"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="维修规模" prop="scale">
                <el-select v-model="ruleFormRepair.scale" size="small" style="width: 210px;"
                           placeholder="请选择项目规模" @change="selectScale">
                  <el-option v-for="item in repairScale" :key="item.id" :label="item.label"
                             :value="item.id"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="维修对象" prop="object" label-width="105px">
                <el-select
                    v-model="ruleFormRepair.object"
                    multiple
                    filterable
                    allow-create
                    size="small" style="width: 210px;"
                    default-first-option
                    placeholder="请选择/输入维修对象">
                  <el-option
                      v-for="item in repairObject"
                      :key="item.value"
                      :label="item.name"
                      :value="item.label">
                  </el-option>
                </el-select>
                <!--                            <el-select v-model="ruleFormRepair.object" size="small" placeholder="请选择维修对象" style="width:100%">-->
                <!--                                <el-option v-for="item in repairObject" :key="item.id" :label="item.label"-->
                <!--                                           :value="item.id"/>-->
                <!--                            </el-select>-->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="施工方案书" prop="file">
                <el-upload
                    class="upload-demo"
                    :action="constructionAction"
                    :before-upload="constructionUrl"
                    :on-success="constructionSuccess"
                    :on-preview="constructionPreview"
                    :on-remove="constructionRemove"
                    :before-remove="beforeRemove"
                    accept=".xls,.xlsx,.doc,.docx,.jpg,.png"
                    multiple
                    :limit="2"
                    :on-exceed="handleExceed"
                >
                  <el-button size="small" style="width: 210px;float: right;margin-top: 3px" type="primary" >+ 请选择文件上传（可上传2个文件）</el-button>
                  <div slot="tip" class="el-upload__tip" style="float: right;margin-right: 47%;color: #f36d6f;margin-top: 0px">*限以下文件格式：Word，Excel，JPG，PNG</div>
                </el-upload>
              </el-form-item>

              <el-form-item label="招标文档" prop="file">
                <el-upload
                    class="upload-demo"
                    :action="constructionAction"
                    :before-upload="constructionUrl"
                    :on-success="constructionSuccess"
                    :on-preview="constructionPreview"
                    :on-remove="constructionRemove"
                    :before-remove="beforeRemove"
                    accept=".xls,.xlsx,.doc,.docx,.jpg,.png"
                    multiple
                    :limit="1"
                    :on-exceed="handleExceed"
                >
                  <el-button size="small" style="width: 210px;float: right;margin-top: 3px" type="primary" >+ 请选择文件上传</el-button>
                  <div slot="tip" class="el-upload__tip" style="float: right;margin-right: 47%;color: #f36d6f;margin-top: 0px">限以下文件格式：Word，Excel，JPG，PNG</div>
                </el-upload>
              </el-form-item>



            </el-col>
          </el-row>
          <el-input v-model="ruleFormRepair.construction1" style="display:none"></el-input>
          <el-input v-model="ruleFormRepair.construction2" style="display:none"></el-input>
          <el-input v-model="ruleFormRepair.construction3" style="display:none"></el-input>
          <el-row>
            <el-col :span="24">
              <el-form-item label="申请现场照片" prop="scenePhoto">
                <el-upload
                    :action="sceneAction"
                    :before-upload="sceneUrl"
                    :on-success="sceneSuccess"
                    list-type="picture-card"
                    :on-preview="scenePreview"
                    :on-remove="sceneRemove"
                    accept=".jpg,.png"
                    multiple
                    :limit="3">
                  <i class="el-icon-plus"></i>
                  <div slot="tip" class="el-upload__tip" style="color: #f36d6f">*限以下文件格式：JPG、PNG，最多上传三张</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>

          <el-form-item label="规模表述" prop="descr">
            <el-col :span="20">
              <el-input type="textarea" placeholder="请输入规模描述" v-model="ruleFormRepair.descr" :rows="10"
                        style="width:100%"></el-input>
            </el-col>
          </el-form-item>

          <el-form-item style="text-align: center;margin-top: 50px;">
            <el-col :span="19">
              <el-button type="primary" size="small" @click="submitForm1('ruleFormRepair')"
                         v-show="firstShowSave">
                下一步
              </el-button>
              <!--                        <el-button type="primary" size="small" @click="firstFormEdit('ruleFormRepair')"-->
              <!--                                   v-show="firstShowEdit">下一步-->
              <!--                        </el-button>-->
              <el-button @click="back" size="small">取消</el-button>
            </el-col>
          </el-form-item>
        </el-form>
      </div>
      <!--        第二步-->
      <!--维修资金分摊信息预览-->
      <div v-if="active==1" style="margin-top: 50px;">
        <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
          <el-dialog title="楼盘图选项" :visible.sync="dialogVisible" width="80%">

            <wuYeCheckHouse @checkHouseData="villageData" v-bind:checkHouseData="checkHouseData"></wuYeCheckHouse>
          </el-dialog>
          <el-row>
            <el-col :span="24">
              <div style="height: 70px; background: #f3f9ff; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
                <div style="margin-left: 20px;">维修小区名称</div>
                <div style="height: 35px; margin-left: 10px; color: #46a0fc; background: #FFF; display: flex; align-items: center;">
                  {{ ruleFormRepair.villageObject.villageName }}
                </div>
                <div style="margin-left: 200px;">维修项目名称</div>
                <div style="height: 35px; margin-left: 10px; color: #46a0fc; background: #FFF; display: flex; align-items: center;">
                  {{ ruleFormRepair.name }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <el-form-item label="预算金额" prop="moneyCount">
                <el-input v-model.number="ruleForm2.moneyCount"
                          label-width="150px"
                          size="small"
                          placeholder="请输入预算总金额"
                          oninput="value=value.replace(/[^\d.]/g,'')" autocomplete="off"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="分摊计算方式" label-width="150px" :prop="'shareId'">
                <el-select size="small" v-model="ruleForm2.shareId" placeholder="请选择分摊计算方式" @change="selectCalculation">
                  <el-option v-for="item in calculList" :key="item.id" :label="item.label"
                             :value="item.value"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <el-button type="text" @click="uploadfile" :disabled="this.ruleForm2.shareId != '2'">导入</el-button>
              <el-dialog
                  title="数据导入"
                  :visible.sync="ImportDialogVisible"
                  width="30%"
                  style="text-align: center">
                <el-upload
                    accept=".xlsx"
                    class="upload-demo"
                    drag
                    ref="upload"
                    :action="upfileUrl"
                    :limit="1"
                    :auto-upload="false"
                    :before-upload="beforeHistoryUpload"
                    :on-success="handleSuccess"
                    multiple>
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                  <div class="el-upload__tip" slot="tip">只能上传xls/xlsx 格式文件
                    <el-link :underline="false" type="primary" @click="downtemplate">模板下载</el-link>
                  </div>
                </el-upload>
                <span slot="footer" class="dialog-footer">
                               <el-button @click="ImportDialogVisible = false">取 消</el-button>
                               <el-button type="primary" @click="submitHistoryUpload">导入</el-button>
                            </span>
              </el-dialog>
            </el-col>
          </el-row>

          <el-form-item style="text-align: center;">
            <el-col :span="1">
              <!--                        <el-button size="small" @click="goBackToFirst">上一步</el-button>-->
              <el-button type="primary" size="small" @click="openCheckHouse('ruleForm2')" :disabled="this.ruleForm2.shareId == (''||'2') || this.ruleForm2.moneyCount == ''">楼盘选择</el-button>

              <!--                        <el-button type="primary" size="small" @click="submitForm2('ruleForm2')">立即创建</el-button>-->
              <!--                        <el-button size="small" @click="resetForm('ruleForm2')">重置</el-button>-->
              <!--                        <el-button size="small" @click="toThree">下一步</el-button>-->
            </el-col>
          </el-form-item>
          <el-row>
            <el-col :span="1.9">
              <el-button type="primary" size="small" @click="exportRepairDetail" plain>导出</el-button>
            </el-col>
            <el-col :span="2">
              <el-button type="primary" size="small" @click="supplementaryPrint" plain>补缴清册打印</el-button>
              <el-dialog title="补缴清册打印" :visible.sync="Printing">

                            <span style="display: flex; justify-content: center;">
                            </span>
                <el-table :data="SupplementaryData" :cell-style="{'text-align':'center'}" id="SupplementaryList">
                  <el-table-column property="villageName" label="小区" align="center"/>
                  <el-table-column property="towerNum" label="楼幢" align="center"/>
                  <el-table-column property="unitName" label="单元" align="center"/>
                  <el-table-column property="houseNum" label="户室" align="center"/>
                  <el-table-column property="acctBal" label="账户余额" align="center"/>
                  <el-table-column property="bjMoney" label="补缴金额" align="center"/>
                </el-table>
                <span style="display: flex; justify-content: center;margin-top: 10px">
<!--                                <el-input v-model="ruleForm2.query" style="width: 300px;"></el-input>-->
                  <!--                                <el-button type="primary" icon="el-icon-search"-->
                  <!--                                           style="margin-left: 10px;">查询</el-button>-->
                                <el-button type="primary" icon="el-icon-printer"
                                           style="margin-left: 10px;" @click="CreateOneFormPage()">打印</el-button>
                                <el-button type="primary" icon="el-icon-download"
                                           style="margin-left: 10px;"
                                           @click="exportBjRepairDetail">导出</el-button>
                            </span>
              </el-dialog>
            </el-col>
          </el-row>
        </el-form>

        <el-dialog
            title="提示"
            :visible.sync="dialogVisibleSuccess"
            width="30%"
        >
          <span>{{ resMessage }}</span>
          <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisibleSuccess = false">取 消</el-button>
                    <el-button type="primary" @click="repairShareDetailList">确 定</el-button>
                </span>
        </el-dialog>

        <!--            分摊信息表格-->
        <el-card>
          <template>
            <el-table
                :data="stap2List"
                stripe
                v-loading="loadingStap2"
                :element-loading-text="loadingText2"
                style="width: 100%">
              <el-table-column
                  type="index"
                  :index='indexMethod'
                  label="序号"
                  width="50">
              </el-table-column>
              <el-table-column
                  prop="ownerName"
                  label="业主姓名">
              </el-table-column>
              <el-table-column
                  prop="villageName"
                  label="小区名称">
              </el-table-column>
              <el-table-column
                  prop="towerNum"
                  label="楼幢号">
              </el-table-column>
              <el-table-column
                  prop="unitName"
                  label="单元名称">
              </el-table-column>
              <el-table-column
                  prop="houseNum"
                  label="户室编号">
              </el-table-column>
              <el-table-column
                  prop="coveredarea"
                  label="户室面积">
              </el-table-column>
              <el-table-column
                  prop="repairName"
                  label="维修项目名称"
                  width="120">
              </el-table-column>
              <el-table-column
                  prop="moneyCount"
                  label="预算总金额">
              </el-table-column>
              <el-table-column
                  prop="money"
                  label="分摊金额">
              </el-table-column>
            </el-table>
          </template>
          <el-pagination
              background
              layout="prev, pager, next"
              :page-size="15"
              @current-change="CurrentChangeStep2"
              :total="stap2Total">
          </el-pagination>
        </el-card>
        <div style="text-align: center;">
          <el-button style="margin-top: 12px;"  type="primary" size="small" @click="SecondFormEdit" >上一步</el-button>
          <el-button style="margin-top: 12px; margin-left: 10px;" type="primary" size="small" @click="SecondShowSave" :disabled="stap2List == ''">下一步</el-button>
        </div>
      </div>

      <!--第三步-->
      <div v-if="active==2" style="margin-top: 50px;">
        <span style="margin-left: 5px; font-size: 13px; color: rgba(153, 153, 153, 1)">投票信息</span>
        <el-form :model="ruleFormRepair" :rules="rules3" ref="ruleForm3" label-position="right"
                 label-width="100px"
                 class="demo-ruleForm"
                 :element-loading-text="loadingText3"
                 v-loading="loadingText3"
                 style="margin-top: 10px;">
          <el-row>
            <el-col :span="12">
              <el-form-item label="投票事项类型">
                <el-input v-model="voteType" size="small" style="width: 210px;" readonly/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="投票事项名称">
                <el-input v-model="ruleFormRepair.object" size="small" style="width: 210px;"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label=投票起始时间>
                <el-date-picker
                    style="width: 210px;"
                    size="small"
                    v-model="ruleFormRepair.voteStartTime"
                    type="datetime"
                    placeholder="开始日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :picker-options="pickerOptions0">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="投票截止时间">
                <el-date-picker
                    style="width: 210px;"
                    size="small"
                    v-model="ruleFormRepair.voteEndTime"
                    type="datetime"
                    placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :picker-options="pickerOptions1">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="参与投票户数">
                <el-input v-model="stap2List.length" size="small" style="width: 210px;" readonly/>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="总建筑面积">
                <el-input v-model="sumCoveredarea" size="small" style="width: 210px;" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="投票选项"  prop="voteOption">
                <template>
                  <el-checkbox v-model="checked" disabled>同意</el-checkbox>
                  <el-checkbox v-model="checked" disabled>不同意</el-checkbox>
                  <el-checkbox v-model="checked" disabled>弃权</el-checkbox>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="选择投票方式" prop="publicity">
                <el-radio-group v-model="ruleFormRepair.publicity">
                  <el-radio  label="1">线上投票</el-radio>
                  <el-radio  label="2">线下投票</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <div style="text-align: center;">
            <el-button style="margin-top: 12px;"  type="primary" size="small" @click="ThirdFormEdit" >上一步</el-button>
            <el-button style="margin-top: 12px; margin-left: 10px;" type="primary" size="small" @click="initiateVotes">发起投票</el-button>
            <el-button style="margin-top: 12px; margin-left: 10px;" size="small" @click="saveVote">保存</el-button>
          </div>
        </el-form>

        <!--发起投票弹出层-->
        <el-dialog title="发起投票" :visible.sync="initiateVote">
          <div style="height: 50px; background: #f3f9ff; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
            <div style="margin-left: 20px; color: #46a0fc">项目基础信息</div>
          </div>

          <div style="height: 70px; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
            <div style="margin-left: 20px; color: rgba(138, 138, 138, 1)">维修小区名称：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ ruleFormRepair.villageObject.villageName }}
            </div>
          </div>
          <div style="height: 70px; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
            <div style="margin-left: 20px; color: rgba(138, 138, 138, 1)">使用类型：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ ruleFormRepair.useType }}
            </div>
            <div style="margin-left: 20px; color: rgba(138, 138, 138, 1)">项目名称：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ ruleFormRepair.name }}
            </div>
            <div style="margin-left: 20px; color: rgba(138, 138, 138, 1)">项目规模：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ ruleFormRepair.repairScale}}
            </div>
            <div style="margin-left: 20px; color: rgba(138, 138, 138, 1)">维修对象：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ ruleFormRepair.object }}
            </div>
          </div>
          <div style="height: 70px; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
            <div style="margin-left: 20px; color: rgba(138, 138, 138, 1)">维修方案描述：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ ruleFormRepair.descr }}
            </div>
          </div>
          <div style="height: 50px; background: #f3f9ff; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
            <div style="margin-left: 20px; color: #46a0fc">预览信息分摊</div>
          </div>
          <div style="height: 70px; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
            <div style="margin-left: 20px; color: rgba(138, 138, 138, 1)">预算金额：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ moneySum+"元" }}
            </div>
            <div style="margin-left: 50px; color: rgba(138, 138, 138, 1)">分摊计算方式：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ ruleForm2.allocationMethod }}
            </div>
            <div style="margin-left: 50px; color: rgba(138, 138, 138, 1)">施工方案书：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{  }}
            </div>
          </div>
          <div style="height: 70px; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
            <div style="margin-left: 20px; color: rgba(138, 138, 138, 1)">楼盘信息：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ "合计："+towerCount+"幢楼、"+unitCount+"个单元、"+houseCount+"户；建筑面积："+areaCount+"m²" }}
            </div>
          </div>
          <div style="height: 50px; background: #f3f9ff; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
            <div style="margin-left: 20px; color: #46a0fc">投票信息</div>
          </div>
          <div style="height: 70px; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
            <div style="margin-left: 20px; color: rgba(138, 138, 138, 1)">投票事项类型：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              维修项目投票
            </div>
            <div style="margin-left: 120px; color: rgba(138, 138, 138, 1)">投票事项名称：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ ruleFormRepair.name }}
            </div>
          </div>
          <div style="height: 70px; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
            <div style="margin-left: 20px; color: rgba(138, 138, 138, 1)">投票起始时间：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ ruleFormRepair.voteStartTime }}
            </div>
            <div style="margin-left: 72px; color: rgba(138, 138, 138, 1)">投票截止时间：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ ruleFormRepair.voteEndTime }}
            </div>
          </div>
          <div style="height: 70px; margin-top: -30px; margin-bottom: 15px; display: flex; align-items: center;">
            <div style="margin-left: 20px; color: rgba(138, 138, 138, 1)">投票选项：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              同意，不同意，弃权
            </div>
            <div style="margin-left: 106px; color: rgba(138, 138, 138, 1)">投票方式：</div>
            <div style="height: 35px; display: flex; align-items: center;">
              {{ ruleFormRepair.publicity == 1?'线上投票':'线下投票' }}
            </div>
          </div>

          <div slot="footer" class="dialog-footer">
            <el-button @click="initiateVote = false">取 消</el-button>
            <el-button type="primary" @click="toThree">确 定</el-button>
          </div>
        </el-dialog>
      </div>

      <!--        第四步-->
      <div v-if="active==3" style="margin-top: 50px;">
        <div style="display: flex; justify-content: center;">
          <img src="../../static/image/submitReview.png" style="width: 400px; height: 260px; margin-top: 10px;" />
        </div>
        <div style="display: flex;justify-content: center;">
          <div style="font-size: 16px; float: left; margin-top: 9px;">
            维修项目处于投票阶段，请等待投票结束后提交审核
          </div>
          <el-button style="float: left; font-size: 16px; margin-left: 5px;" type="text" @click="voteMessage">查看投票信息</el-button>
        </div>
        <div style="text-align: center;">
          <el-button style="margin-top: 10px;"  type="primary" size="small" @click="backHome" >返回首页</el-button>
          <!--                <el-button style="margin-top: 10px; margin-left: 10px;" size="small" @click="submitReview" >提交审核</el-button>-->
        </div>

      </div>
      <!--        第五步-->
      <div v-if="active==4" style="margin-top: 50px;">
        <div v-if="fundingPlan==2">
          <div style="display: flex; justify-content: center;">
            <img src="../../static/image/reviewAdopt.png" style="width: 400px; height: 260px; margin-top: 10px;" />
          </div>
          <div style="text-align: center;">
            <div style="font-size: 16px; margin-top: 9px;">
              尊敬的用户，您好
            </div>
            <div style="font-size: 16px; margin-top: 9px;">
              拨款申请已提交成功，请耐心等待房管局审核之后银行拨款
            </div>
          </div>
          <div style="text-align: center;">
            <el-button style="margin-top: 10px;"  type="primary" size="small" @click="backHome" >返回首页</el-button>
            <el-button style="margin-top: 10px; margin-left: 10px;" size="small" @click="seeProgress" >进度</el-button>
          </div>
        </div>
        <div v-if="fundingPlan==1">
          <el-form ref="ruleForm2" :model="ruleForm2" label-width="80px" :inline="true" :rules="rules">
            <el-form-item label="施工单位" prop="company">
              <el-input v-model="ruleForm2.company" size="small" style="width: 210px"
                        placeholder="请输入施工单位名称"></el-input>
            </el-form-item>
            <el-form-item label="施工项目" style="margin-left: 100px">
              <el-input :readonly="true" v-model="ruleFormRepair.name" size="small" style="width: 210px"
                        placeholder="施工项目"></el-input>
            </el-form-item>
            <el-form-item label="合同金额" prop="moneyCount">
              <el-input :readonly="true" v-model.number="ruleForm2.moneyCount" size="small" style="width: 210px"
                        placeholder="合同金额" oninput="value=value.replace(/[^\d.]/g,'')" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="分期期数" prop="fqqs" style="margin-left: 100px">
              <el-input @input="getFqqs" v-model="ruleForm2.fqqs" size="small" style="width: 210px"
                        placeholder="请输入分期期数"></el-input>
            </el-form-item>
            <el-form-item label="施工合同">
              <el-upload
                  class="upload-demo"
                  :action="contractAction"
                  :before-upload="contractUrl"
                  :on-preview="contractPreview"
                  :on-remove="contractRemove"
                  :on-success="contractSuccess"
                  :before-remove="beforeRemove"
                  accept=".xls,.xlsx,.doc,.docx"
                  multiple
                  :limit="1"
                  :on-exceed="handleExceed"
              >
                <el-button size="small" style="width: 210px; float: left;" type="primary" plain>+ 请上传施工合同</el-button>
                <div slot="tip" class="el-upload__tip" style="float: right; margin-top: -0.9%; margin-left: 30px;">限以下文件格式：Word，Excel</div>
              </el-upload>
            </el-form-item>
            <el-input v-model="ruleFormRepair.contract" style="display:none"></el-input>
          </el-form>

          <el-card>
            <template>
              <el-table
                  :data="tableData"
                  stripe
                  :element-loading-text="loadingText4"
                  v-loading="loading"
                  style="width: 100%">
                <el-table-column
                    type="index"
                    label="拨款批次"
                    width="90">
                </el-table-column>
                <el-table-column
                    label="拨款金额">
                  <template slot-scope="scope">
                    <el-input :disabled="ruleForm2.fqqs==1||ifCheckStap3" size="small"
                              @blur="getBiLI(scope.$index,scope.row)"
                              v-model="scope.row['bkje'+scope.$index]"
                              placeholder="请输入拨款金额"></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                    label="预付款日期">
                  <template slot-scope="scope">
                    <el-date-picker
                        v-model="scope.row['yflDate'+scope.$index]"
                        type="date"
                        size="small"
                        placeholder="选择日期时间">
                    </el-date-picker>
                  </template>
                </el-table-column>
                <el-table-column
                    label="拨款比例">
                  <template slot-scope="scope">
                    <el-input :disabled="true" size="small" v-model="scope.row['bkbl'+scope.$index]"
                              placeholder="拨款比例"></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                    label="开户银行">
                  <template slot-scope="scope">
                    <el-input  size="small" v-model="scope.row['khh'+scope.$index]"
                               placeholder="开户银行"></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                    label="银行账户户名">
                  <template slot-scope="scope">
                    <el-input size="small" v-model="scope.row['zhhm'+scope.$index]"
                              placeholder="银行账户户名"></el-input>
                  </template>
                </el-table-column>
                <el-table-column
                    label="银行账户账号">
                  <template slot-scope="scope">
                    <el-input size="small" v-model="scope.row['zhzh'+scope.$index]"
                              placeholder="银行账户账号"></el-input>
                  </template>
                </el-table-column>
                <!--                        <el-table-column-->
                <!--                                label="备注">-->
                <!--                            <template slot-scope="scope">-->
                <!--                                <el-input size="small" v-model="scope.row['beizhu'+scope.$index]"-->
                <!--                                          placeholder="请输入备注"></el-input>-->
                <!--                            </template>-->
                <!--                        </el-table-column>-->

              </el-table>
            </template>
            <div class="stap3Button">
              <el-button size="small" type="primary" @click="resetStap3">重置</el-button>
              <el-button size="small" type="primary" @click="step3_send">提交</el-button>
              <!--<el-button size="small" type="primary" @click="toStap4">下一步</el-button>-->
            </div>
          </el-card>
        </div>

      </div>

      <!--第六步-->
      <div v-if="active==5" style="margin-top: 50px;">
        <vote :id="shareId"></vote>
      </div>

    </div>
    <el-dialog
        :visible.sync="dialogVisible1"
        width="50%"
        center>
      <span class="title">拨款申请进度</span>
      <el-steps :active="active1" align-center style="margin-top: 70px;">
        <el-step title="提交" :description="timeNo1"></el-step>
        <el-step title="初审" :description="timeNo2"></el-step>
        <el-step title="复审" :description="timeNo3"></el-step>
      </el-steps>
      <span slot="footer" class="dialog-footer">
            <el-button type="primary"  @click="dialogVisible1 = false">返回</el-button>
          </span>
    </el-dialog>
  </div>
</template>

<script>
import wuYeCheckHouse from "./wuYeCheckHouse";
import commonTitle from "../table/commonTitle";
import vote from "./vote";
import print from 'print-js';

export default {
  name: "wuYeManagement",
  components: {
    wuYeCheckHouse,
    commonTitle,
    vote,
    print
  },
  watch: {
    "ruleFormRepair.village"(e) {
      let filterElement = this.repairVillage.filter(f => f.villageId == e)[0];
      this.ruleFormRepair.villageObject = filterElement
    }
  },
  data() {

    var nowDate = new Date();

    var year = nowDate.getFullYear();

    var month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1)

        : nowDate.getMonth() + 1;

    var day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate

        .getDate();

    return {
      active1:1,
      createRepairId: '',//维修项目id
      villageCount: '-',
      unitCount: '-',
      houseCount: '-',
      areaCount: '-',
      towerCount: '-',
      SupplementaryData: [],
      input: '',
      constructionAction: '',
      sceneAction: '',//施工方案上传
      contractAction: '',//合同
      timeNo1: '',
      timeNo2: '',
      timeNo3: '',
      fileList: '',
      upfileUrl: '',
      tableData: [],//步骤三里面提交的数据
      dialogVisible: false, //弹出层控制
      dialogVisibleSuccess: false,//导出成功弹窗
      active: 0, //步骤条标记
      actives: 0,
      show_row_form: {
        rows: 1
      },
      ruleFormRepair: { //第一步  项目基础信息
        construction1: '',//施工方案书1
        construction2: '',//施工方案书2
        construction3: '',//施工方案书3
        scene1: '',//现场照片1
        scene2: '',//现场照片2
        scene3: '',//现场照片3
        contract: '',//施工合同
        useType: '',//使用类型名字
        repairScale: '',//维修规模名字
        manageId: '', //房管局id
        type: '', //使用类型
        name: '', //项目名称
        object: '', //维修对象
        scale: '', //维修规模
        descr: '', //维修方案描述
        id: '',//维修方案id
        village: '',//小区名称
        voteStartTime: '',
        voteEndTime: '',
        publicity: '1',
      },
      ruleForm2: { //第二步  分摊信息
        repairId: '',//维修项目Id
        moneyCount: '', //预算总金额
        company: '',//施工单位
        areaId: '', //所属区域
        fqqs:'',
        allocationMethod: '',//分摊方式名称
        shareId: null, //分摊方式
        query: '',
        villageId: ''//小区id
      },
      ruleForm3: {
      },
      voteType: "维修项目投票",
      resMessage: '',
      repairVillage: [],
      repairList: [], //使用类型
      repairObject: [], //维修对象
      repairScale: [], //维修规模
      calculList: [], //分摊计算方式
      rules: {
        company: [{
          required: true,
          message: '请输入施工单位名称',
          trigger: 'change'
        }],
        fqqs: [{
          required: true,
          message: '请输入分期期数',
          trigger: 'change'
        }],
        moneyCount: [{
          required: true,
          message: '请输入合同金额',
          trigger: 'change'
        }]
      },
      rules1: {
        manageId: [{
          required: true,
          message: '请选择房管局',
          trigger: 'change'
        }],
        village: [{
          required: true,
          message: '请选择维修小区',
          trigger: 'change'
        }],
        type: [{
          required: true,
          message: '请选择使用类型',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '请输入项目名称',
          trigger: 'change'
        }],
        object: [{
          required: true,
          message: '请输入/选择维修对象',
          trigger: 'change'
        }],
        scale: [{
          required: true,
          message: '请选择维修规模',
          trigger: 'change'
        }],
        descr: [{
          required: true,
          message: '请输入相关规模描述',
          trigger: 'change'
        }]
      },
      rules2: {
        moneyCount: [
          {
            type: 'number',
            message: '预算总金额必须为数字值'
          }
        ],
        areaId: [{
          required: true,
          message: '请选择所属区域',
          trigger: 'change'
        }],
        shareId: [{
          required: true,
          message: '请选择分摊计算方式',
          trigger: 'change',
        }
        ]
      },
      rules3: {
        publicity: [
          { trigger: 'change' }
        ],
      },
      pickerOptions0: {
        disabledDate: (time) => {
          if (this.voteEndTime != "") {
            return time.getTime() >  new Date(this.ruleFormRepair.voteEndTime).getTime();

          }
        }
      },
      pickerOptions1: {
        disabledDate: (time) => {
          return time.getTime() < new Date(this.ruleFormRepair.voteStartTime).getTime();
        }
      },
      txtNumber: '1',
      fundingPlan:'1',
      radio: '1',
      checked: true,
      gridData: [{}],
      Printing: false,
      dialogVisible1: false,
      ImportDialogVisible: false,
      initiateVote: false,
      url: this.$Config.base_server,
      loading: false,
      houseIds: [],//选中的户室id数组
      shareId: '',//第一步返回的shareid

      step2Id: '',//第二步创建成功返回的id
      stap2List: [],//第二步表格数据
      stap2Total: 10,//第二步总页数
      currentPageStap2: 1,//第二步表格分页当前页
      loadingStap2: false,//第二步表格加载
      toThreeStatus: false,
      publicData: {},

      stap3Data: [],//第三步向后台提交数据
      ifCheckStap3: false,//第三步填完是否重置
      ifToStap4: false,//是否
      loadingText1: '',//加载提示语
      loadingText2: '',//加载提示语
      loadingText3: '',//加载提示语
      loadingText4: '',//加载提示语
      firstShowSave: true,//控制是否显示保存并进入下一步
      firstShowEdit: false,//控制是否显示修改并进入下一步
      //需要传给户室选择子组件的值
      checkHouseData: '',
      lpxzCount: -1,//记录点击楼盘选择的次数 不等于0的是否就点击超过1次
      moneySum: '',
      sumCoveredarea: '',
    }

  },
  methods: {
    constructionUrl(){
      return new Promise((resolve, reject) => {

        this.$nextTick(() => {
          this.constructionAction = this.url + "/api-file/files/anon?access_token=" + localStorage.getItem("token"),
              resolve()

        })
      })
    },
    sceneUrl(){
      return new Promise((resolve, reject) => {

        this.$nextTick(() => {
          this.sceneAction = this.url + "/api-file/files/anon?access_token=" + localStorage.getItem("token"),
              resolve()

        })
      })
    },
    contractUrl(){
      return new Promise((resolve, reject) => {

        this.$nextTick(() => {
          this.contractAction = this.url + "/api-file/files/anon?access_token=" + localStorage.getItem("token"),
              resolve()

        })
      })
    },
    constructionSuccess(file){
      if (null==this.ruleFormRepair.construction1||""==this.ruleFormRepair.construction1){
        this.ruleFormRepair.construction1=file.id;
      } else if (null==this.ruleFormRepair.construction2||""==this.ruleFormRepair.construction2){
        this.ruleFormRepair.construction2=file.id;
      } else if (null==this.ruleFormRepair.construction3||""==this.ruleFormRepair.construction3){
        this.ruleFormRepair.construction3=file.id;
      }

    },
    sceneSuccess(file){
      if (null==this.ruleFormRepair.scene1||""==this.ruleFormRepair.scene1){
        this.ruleFormRepair.scene1=file.id;
      } else if (null==this.ruleFormRepair.scene2||""==this.ruleFormRepair.scene2){
        this.ruleFormRepair.scene2=file.id;
      } else if (null==this.ruleFormRepair.scene3||""==this.ruleFormRepair.scene3){
        this.ruleFormRepair.scene3=file.id;
      }
      console.log(file);
    },
    scenePreview(file) {
      console.log(file);
    },
    sceneRemove(file) {
      if (this.ruleFormRepair.scene1==file.response.id){
        this.ruleFormRepair.scene1=null;
      } else if (this.ruleFormRepair.scene2==file.response.id){
        this.ruleFormRepair.scene2=null;
      } else if (this.ruleFormRepair.scene3==file.response.id){
        this.ruleFormRepair.scene3=null;
      }
      console.log(file)

    },
    contractSuccess(file){
      if (null==this.ruleFormRepair.contract||""==this.ruleFormRepair.contract){
        this.ruleFormRepair.contract=file.id;
      }
      console.log(file)
    },
    contractPreview(file){
      console.log(file);
    },
    contractRemove(file){
      if (this.ruleFormRepair.contract==file.response.id){
        this.ruleFormRepair.contract=null;
      }
      console.log(file)
    },
    constructionPreview(file){
      console.log(file)
    },
    constructionRemove(file){
      if (this.ruleFormRepair.construction1==file.response.id){
        this.ruleFormRepair.construction1=null;
      } else if (this.ruleFormRepair.construction2==file.response.id){
        this.ruleFormRepair.construction2=null;
      } else if (this.ruleFormRepair.construction3==file.response.id){
        this.ruleFormRepair.construction3=null;
      }
      console.log(file)
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    initiateVotes () {
      const that = this;
      that.initiateVote = true
      that.ruleFormRepair.voteStartTime=this.ruleFormRepair.voteStartTime
      that.ruleFormRepair.voteEndTime=this.ruleFormRepair.voteEndTime
      that.ruleFormRepair.id=this.createRepairId
      that.ruleFormRepair.publicity=this.ruleFormRepair.publicity
      $.ajax({
        url: that.url+"/api-public/repairShareDetail/findBuildingInformation?createrepairID=" + that.ruleFormRepair.id +"&access_token=" + localStorage.getItem("token"),
        type: 'post',
        data: JSON.stringify(that.ruleFormRepair.id),
        contentType: 'application/json',
        success: function (result) {
          that.towerCount=result[1].towerCount;
          that.unitCount=result[1].unitCount;
          that.houseCount=result[1].houseCount;
          that.areaCount=result[0].areaCount;
        },
      })
      $.ajax({
        url: that.url + "/api-public/repairShareDetail/saveStartEndTime?access_token=" + localStorage.getItem("token"),
        type: 'post',
        data: JSON.stringify(this.ruleFormRepair),
        contentType: 'application/json',
        success: function (result) {
          return "success";
        },
      })
    },
    voteMessage() {
      this.publicData.endTime = this.ruleFormRepair.voteEndTime
      this.publicData.startTime = this.ruleFormRepair.voteStartTime
      this.publicData.createRepairId = this.createRepairId
      this.$router.push({path: 'votePublic', query: {publicData: this.publicData,endTime: this.ruleFormRepair.voteEndTime,createRepairId: this.createRepairId}});
    },
    selectValue() {
      for(var i = 0; i < this.repairList.length; i++) {
        if(this.ruleFormRepair.type == this.repairList[i].id) {
          this.ruleFormRepair.useType = this.repairList[i].label
        }
      }
    },
    selectScale(){
      for(var i = 0; i < this.repairScale.length; i++) {
        if(this.ruleFormRepair.scale == this.repairScale[i].id) {
          this.ruleFormRepair.repairScale = this.repairScale[i].label
        }
      }
    },
    selectCalculation(){
      for(var i = 0; i <= this.calculList.length; i++) {
        if(this.ruleForm2.shareId == this.calculList[i].value) {
          this.ruleForm2.allocationMethod = this.calculList[i].label
          return
        }
      }
    },
    CreateOneFormPage() {
      print({printable: 'SupplementaryList',

        type:'html',

        header:'补缴清册名单',

        headerStyle:'text-align:center;color:#f00;width:100%;border:1px #000 solid;',


        scanStyles:false,

        style:'table tr td,th { border-collapse: collapse;padding:15px;border:1px#000 solid; }'// 表格样式

      })

    },
    backHome(){
      this.$router.push({ name: "repairItem" });
    },
    seeProgress(){
      const that = this;
      $.ajax({
        url: that.url + "/api-public/approvalProgressTime?createRepairId=" + that.createRepairId + "&access_token=" + localStorage.getItem("token"),
        type: 'get',
        data: that.createRepairId,
        contentType: 'application/json',
        success: function (res) {
          that.timeNo1=res.timeNo1;
          that.timeNo2=res.timeNo2;
          that.timeNo3=res.timeNo3;
          if (that.timeNo3==null&&that.timeNo2==null){
            that.active1=1;
          }else if(that.timeNo3==null && that.timeNo2!=null){
            that.active1=2;
          }else {
            that.active1=3;
          }
        },
        error: function (err) {

        }
      });
      this.dialogVisible1=true;
    },
    toStap4() {//跳转第四步
      if (this.ifToStap4) {
        this.active = 4;
      }
    },
    uploadfile(row) {
      //数据导入...
      const that = this;
      that.row = row;
      this.upfileUrl = this.url + "/api-public/repairShareDetail/importExcel?villageId=" + this.ruleFormRepair.villageObject.villageId + "&createrepairID=" + this.createRepairId + "&access_token=" + localStorage.getItem("token");

      //this.upfileUrl=this.url + "/api-public/historyData/kfsimportFile?villageId="+villageId+"&access_token=" + localStorage.getItem("token");
      this.ImportDialogVisible = true;

    },
    SecondFormEdit(){
      //第二步返回第一步
      this.active = 0;
      this.firstShowSave = true
      $.ajax({
        url: this.url + "/api-public/createRepair/delete/" + this.createRepairId + "?access_token=" + localStorage.getItem("token"),
        type: 'delete',
        // success: function (data) {
        //     this.loading = false;
        //     if (data.resp_code == 0) {
        //         tableData.splice(index, 1);
        //         //成功
        //         this.$message({
        //             type: 'success',
        //             message: data.resp_msg
        //         });
        //     }
        //     if (data.resp_code == 1) {
        //         //失败
        //         _this.$message({
        //             type: "error",
        //             message: data.resp_msg
        //         });
        //     }
        // },
        // error: function (res) {
        //     _this.loading = false;
        // }
      });
    },
    SecondShowSave(){
      const that = this;
      //第二步跳转第三部
      if (that.ruleFormRepair.useType=="应急维修"){
        that.active = 4;
      }else {
        that.active = 1;
        that.moneySum = that.stap2List[0].moneyCount;
        var repair = {
          id: that.createRepairId,
          contractMoney: that.ruleForm2.moneyCount,
          active:that.active
        }
        $.ajax({
          url: that.url + "/api-public/repairShareDetail/updateContract?access_token=" + localStorage.getItem("token"),
          type: 'POST',
          data: JSON.stringify(repair),
          contentType: 'application/json',
          success:function (res){
            that.active = 2;
          }
        })
      }
    },
    ThirdFormEdit(){
      //第三步跳转第二步
      this.active = 1;
    },
    saveVote(){
      //保存投票信息
    },
    exportRepairDetail() {
      //分摊信息导出
      var that = this;
      const  url=that.url + "/api-public/repairShareDetail/exportExcel?repairId=" + that.createRepairId+"&access_token=" + localStorage.getItem("token");
      location.href=url;
    },
    exportBjRepairDetail(){
      //补缴清册导出
      var that = this;
      const  url=that.url + "/api-public/repairShareDetail/exportBjRepairExcel?createrepairID=" + that.createRepairId+"&access_token=" + localStorage.getItem("token");
      location.href=url;
    },
    downtemplate() {
      //下载导入模板
      var that = this;
      location.href = that.url + "/api-public/import/RepairDetailTemplate?access_token=" + localStorage.getItem("token");
    },
    supplementaryPrint() {
      //补缴清册列表
      var that = this;
      that.Printing = true;
      $.ajax({
        url: that.url + "/api-public/bjqdDetail/list?createrepairID=" + that.createRepairId + "&access_token=" + localStorage.getItem("token"),
        type: 'get',
        data: that.createRepairId,
        contentType: 'application/json',
        success: function (res) {
          that.SupplementaryData = res

        },
        error: function (err) {

        }
      });
    },
    beforeHistoryUpload(file) {
      //上传前进行校验格式
      const Xls = file.name.split('.');
      if (Xls[1] === 'xlsx') {
        this.ImportDialogVisible = false;
        this.loading = true;
        return file;
      } else {
        this.$message.error('上传文件只能是xlsx 格式')
        return false
      }

    },
    submitHistoryUpload() {
      //确定上传按钮
      this.$refs.upload.submit();
    },
    repairShareDetailList(){
      const that = this;
      $.ajax({
        url: that.url + "/api-public/repairShareDetail/backViewRepairDetail?repairId=" + that.createRepairId + "&access_token=" + localStorage.getItem("token"),
        type: 'get',
        data: that.createRepairId,
        contentType: 'application/json',
        success: function (res) {
          that.dialogVisibleSuccess = false
          that.stap2List=res.data
          that.sumCoveredarea=res.map.sumCoveredarea
        },
        error: function (err) {

        }
      });
    },
    handleSuccess(response) {

      if (response.resp_code == 0) {
        this.resMessage = response.resp_msg
        this.dialogVisibleSuccess = true

      } else {
        this.$message({
          type: 'error',
          message: response.resp_msg,
          duration: 2000,
          dangerouslyUseHTMLString: true
        });
      }
      this.$refs['upload'].clearFiles();
    },
    getBiLI(index, item) {//输入框输入计算比例
      let bkjq = +item['bkje' + index];//拨款金额
      let ysje = +this.ruleForm2.moneyCount;//预算金额
      //判断输入的值是否合法

      if (bkjq > ysje) {//如果拨款金额大于预算金额
        this.$message({
          message: '拨款金额不能大于预算金额，请重新输入！',
          type: 'warning'
        });
        return
      }

      if (bkjq < 0) {
        this.$message({
          message: '拨款金额不能小于0，请重新输入！',
          type: 'warning'
        });
        return
      }

      let bkbl = bkjq / ysje;//拨款比例
      item['bkbl' + index] = (bkbl * 100) + '%';


      //   判断它是不是最后一个

      //判断还有几个没填
      let arr = [];
      let total = 0;
      this.tableData.forEach((item, index) => {
        //计算空值index
        if (item['bkje' + index] == '') {
          let obj = {
            index
          }
          arr.push(obj)
        } else {
          total +=item['bkje' + index];
        }
        //    计算写入的和

      })

      if (arr.length == 1) {//是最后一个了
        debugger;
        let num = arr[0].index;//这个是最后一个在tableData出现的位置
        this.tableData[num]['bkje' + num] = ysje - total;
        this.tableData[num]['bkbl' + num] = ((ysje - total) / ysje) * 100 + "%";

        //    让不能点击
        this.ifCheckStap3 = true;
      }
    },
    step3_send() {//步骤三提交
      const that = this;
      that.loading = true;
      that.loadingText4 = "拨款计划提交审批中,请稍后..."
      const arr = [];
      this.tableData.forEach((item, index) => {
        let obj = {
          shareid: that.step2Id==''? that.shareId:that.step2Id ,
          appropriationPeriod: index + 1,//拨款批次
          prepaymentDate: item['yflDate' + index],//预付款日期
          prepaymentAmount: item['bkje' + index],//拨款金额
          prepaymentRatio: item['bkbl' + index].substr(0, item['bkbl' + index].length - 1),//拨款比例
          bankName: item['khh' + index],//开户行
          bankAcctName: item['zhhm' + index],//账户户名
          bankAcct: item['zhzh' + index],
          installments: that.ruleForm2.fqqs,
          constructionName: that.ruleForm2.company,
          contract: that.ruleFormRepair.contract,
          contractMoney:that.ruleForm2.moneyCount,
        }
        arr.push(obj)
      });
      $.ajax({
        url: that.url + "/api-public/repairShareDetail/saveAppropriationPlan?access_token=" + localStorage.getItem("token"),
        type: 'post',
        data: JSON.stringify(arr),
        contentType: 'application/json',
        success: function (res) {
          debugger
          if (res.resp_code == 0) {
            $.ajax({
              url: that.url + "/api-hxworkflow/act/deal/startMyFlow?access_token=" + localStorage.getItem("token"),
              data:{businessId:that.createRepairId, actKey: 'repair_flow'} ,
              type: 'post',
              success: function (res) {
                console.log(res)
                if (res.resp_code == 0){
                  that.loading = false;
                  that.$message({
                    message:"流程申请成功，请耐心等待房管局审批！",
                    type:'success',
                    onClose: function () {
                      that.active = 4;
                    }});
                  that.fundingPlan="2";
                }
                else {
                  that.$message.error({message:"申请失败"});
                }
              },
              error:function(res){
                that.$message.error({message:"网络错误"});
              },
            });
          }else {
            that.loading = false;
            that.$message.error({message:res.resp_msg});
          }
        },
        error: function (err) {
          that.loading = false;
        }
      });
    },
    getFqqs() {//根据分期期数循环下面的表格
      const that = this;
      let num = Number.parseInt(this.ruleForm2.fqqs);//填写数字
      that.tableData = [];
      that.ifCheckStap3 = false;
      //限制分期金额不能大于10
      if (num > 10) {
        this.$message({
          message: '分期金额不能大于10，请重新输入',
          type: 'warning'
        });
        return
      }
      //限制分期金额不能小于1
      if (num < 1) {
        this.$message({
          message: '分期金额不能小于1，请重新输入',
          type: 'warning'
        });
        return
      }


      for (let i = 0; i < num; i++) {
        let data = {
          ['bkje' + i]: '',//拨款金额
          ['yflDate' + i]: '',//预付款日期
          ['bkbl' + i]: '', //拨款比例
          ['beizhu' + i]: '',//备注
          ['khh' + i]: '',//开户行
          ['zhhm' + i]: '',//第三方账户户名
          ['zhzh' + i]: ''//第三方账户账号

        }
        that.tableData.push(data)
      }


      //当分期数为1时

      if (num == 1) {
        that.tableData[0].bkje0 = that.ruleForm2.moneyCount;//让输入框中的等于预算金额
        that.tableData[0].bkbl0 = '100%';//让值为100%
      } else {//当分期数不为1时

      }

    },
    toThree() {//第二步跳第三步.
      const _this = this;
      if (_this.ruleFormRepair.publicity == 1){
        $.ajax({
          url: _this.url + "/api-public/vote/save?access_token=" + localStorage.getItem("token"),
          type: 'post',
          data: JSON.stringify(_this.ruleForm),
          contentType: 'application/json',
          success: function (res) {
            _this.loading = false;
            console.log(res);
            if (res.resp_code == 0) {
              _this.$message({
                message: res.resp_msg,
                type: 'success',
                onClose: function () {
                  //跳转到维修项目页面
                  _this.$router.push({path:'repairItem',query:{createRepairId: _this.createRepairId}});
                }
              });
            }
          }
        });
        _this.toThreeStatus == true//跳到第三步
        _this.active = 3;
      }else if (_this.ruleFormRepair.publicity == 2){
        _this.publicData.endTime = _this.ruleFormRepair.voteEndTime
        _this.$router.push({path: 'votePublic', query: {createRepairId: _this.createRepairId,lineState:true,publicData: _this.publicData,endTime: _this.ruleFormRepair.voteEndTime}});
      }

    },
    goBackToFirst() {
      //第二步返回到第一步
      this.active = 1;
      //显示修改并进入到下一步按钮
      this.firstShowEdit = true;
    },
    indexMethod(index) {//分页序号
      return (index + 1) + (this.currentPageStap2 - 1) * 15;
    },
    resetStap3() {
      let arr = [];
      this.tableData.forEach((item, index) => {
        let obj = {
          ['bkje' + index]: "",
          ['yflDate' + index]: '',
          ['bkbl' + index]: "",
          ['beizhu' + index]: "",
        }
        arr.push(obj)
      })
      this.tableData = arr;
      this.ifCheckStap3 = false;
    },
    CurrentChangeStep2(val) {//第二步分页点击
      this.currentPageStap2 = val;
      this.step2TableRequest(val)
    },
    step2TableRequest(currentPage = 1) {//第二步请求表格数据
      this.loadingStap2 = true;//加载显示
      //  this.toThreeStatus = true;//可以跳到第三步
      const _this = this;
      const data = {
        id: this.step2Id,
        page: currentPage,
        limit: 15,
      }
      $.ajax({
        url: _this.url + "/api-public/repairShareDetail/backViewRepairDetail?access_token=" + localStorage.getItem("token"),
        type: 'get',
        data,
        contentType: 'application/json',
        success: function (res) {
          _this.loadingStap2 = false;
          console.log(res);
          if (res.code == 0) {
            _this.stap2Total = res.count;
            _this.stap2List = res.data;
            _this.sumCoveredarea=res.map.sumCoveredarea
            if (res.count != 0) {
              _this.toThreeStatus = true;//可以跳到第三步
            }
          }
        },
        error: function (err) {
          _this.loadingStap2 = false;
        }
      });
    },
    villageData(shareId) {
      this.dialogVisible = false;


      this.step2Id = shareId;
      this.step2TableRequest();//查询表格

    },
    next: function () {
      if (this.active++ > 6) this.active = 0;
    },
    submitForm1: function (formName) {
      var _this = this;
      _this.ruleFormRepair.object=_this.ruleFormRepair.object.join(',');
      _this.ruleFormRepair.active=_this.active;
      _this.loadingText1 = "创建基础信息中，请稍后...";
      this.$refs[formName].validate(function (valid) {
        _this.loading = true;
        if (valid) {
          $.ajax({
            url: _this.url + "/api-public/createRepair/save?access_token=" + localStorage.getItem("token"),
            type: 'post',
            data: JSON.stringify(_this.ruleFormRepair),
            contentType: 'application/json',
            success: function (result) {
              _this.createRepairId = result.datas
              _this.loading = false;
              //成功后进入下一步
              if (result.resp_code == 0) {
                //第一步成功后获取相关数据
                //成功后将返回来的维修项目Id 赋值给ruleForm2中的  repairId
                _this.shareId = result.datas;
                _this.ruleForm2.repairId = result.datas;
                _this.$message({
                  message: result.resp_msg,
                  type: 'success',
                  duration: 1000,
                  onClose: function () {
                    if (_this.active++ > 4) _this.active = 0;
                    //隐藏第一步中的保存并进入下一步按钮
                    _this.firstShowSave = false;
                  }
                });
              } else {
                _this.loading = false;
                //添加失败
                _this.$message({
                  message: result.resp_msg,
                  type: 'warning'
                });
              }
            }
          });
        } else {
          _this.loading = false;
          console.log('error submit!!');
          return false;
        }
      });
    },
    firstFormEdit(formName) {
      //修改并进入下一步
      var _this = this;
      _this.loadingText1 = "修改基础信息中，请稍后...";
      this.$refs[formName].validate(function (valid) {
        _this.loading = true;
        if (valid) {
          _this.ruleFormRepair.id = _this.shareId;
          $.ajax({
            url: _this.url + "/api-public/createRepair/update?access_token=" + localStorage.getItem("token"),
            type: 'post',
            data: JSON.stringify(_this.ruleFormRepair),
            contentType: 'application/json',
            success: function (result) {
              _this.loading = false;
              //成功后进入下一步
              if (result.resp_code == 0) {
                _this.$message({
                  message: result.resp_msg,
                  type: 'success',
                  duration: 1000,
                  onClose: function () {
                    if (_this.active++ > 4) _this.active = 0;
                    //隐藏第一步中的保存并进入下一步按钮
                    _this.firstShowSave = false;
                  }
                });
                // _this.ruleFormRepair.createRepairId=result.
              } else {
                _this.loading = false;
                //添加失败
                _this.$message({
                  message: result.resp_msg,
                  type: 'warning'
                });
              }
            }
          });
        } else {
          _this.loading = false;
          console.log('error submit!!');
          return false;
        }
      });
    },
    submitForm2: function (formName) {
      var _this = this;
      _this.loadingText2 = "分摊信息计算中，请稍后...";
      this.$refs[formName].validate(function (valid) {
        if (valid) {

          if (_this.houseIds.length == 0) {
            _this.$message({
              message: '请先选择楼盘',
              type: 'warning'
            });
            return
          }
          let data = {
            "repairShare": {
              "shareId": _this.ruleForm2.shareId,//
              "moneyCount": _this.ruleForm2.moneyCount,
              "repairId": _this.shareId
            },
            "houseIds": _this.houseIds
          }
          _this.loadingStap2 = true;
          $.ajax({
            url: _this.url + "/api-public/repairShareDetail/save?access_token=" + localStorage.getItem("token"),
            type: 'post',
            data: JSON.stringify(data),
            contentType: 'application/json',
            success: function (result) {

              //成功后进入下一步
              if (result.resp_code == 0) {
                //第一步成功后获取相关数据
                _this.step2Id = result.datas;
                _this.step2TableRequest();//查询表格
                _this.$message({
                  message: result.resp_msg,
                  type: 'success',
                  duration: 1000,
                  // onClose: function () {
                  //     if (_this.active++ > 4) _this.active = 0;
                  // }
                });
              } else {
                //添加失败
                _this.$message({
                  message: result.resp_msg,
                  type: 'warning'
                });
              }
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }, openCheckHouse: function (formName) {
      var _this = this;
      this.$forceUpdate()

      //打开楼盘选择弹出层
      this.$on('el.form.addField', (field) => {
        if (field) {
          this.fields.push(field);
        }
      });
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this.dialogVisible = true;
          const data = {
            "repairShare": {
              "shareId": _this.ruleForm2.shareId,//
              "moneyCount": _this.ruleForm2.moneyCount,
              "repairId": _this.ruleForm2.repairId,
              "villageId": _this.ruleFormRepair.villageObject.villageId
            }
          };
          //记录楼盘选择的次数
          _this.lpxzCount++;
          data.lpxzCount = _this.lpxzCount;
          _this.checkHouseData = data
        } else {
          return false;
        }
      })
    }, back() {
      //取消按钮
      this.$router.go(-1);
    }
  },
  created: function () {
    //初始化数据
    let that = this;
    //根据登陆信息查询房管局信息
    that.txtNumber = this.$route.query.txtNumber;
    if (that.txtNumber==2){
      that.active=this.$route.query.active*1;
      that.fundingPlan=this.$route.query.fundingPlan;
      that.shareId=this.$route.query.shareId;
      that.createRepairId=this.$route.query.createRepairId;
      that.ruleFormRepair.name = this.$route.query.name;
      that.ruleForm2.moneyCount = this.$route.query.contractMoney;
    }else {
      if(typeof (this.$route.query.active)!="undefined"){
        that.active=this.$route.query.active*1;
      }else{
        that.active=0;
      }
      that.fundingPlan=1;
    }

    if (that.createRepairId!=null && that.createRepairId!=""){
      $.get(that.url + "/api-public/createRepair/list?createRepairId="+that.createRepairId+"&access_token=" + localStorage.getItem("token"), function (result) {
        this.ruleFormRepair= result.data;
      });
    }


    $.get(that.url + "/api-public/sysdict/findSysdictByType?key=manager_name&access_token=" + localStorage.getItem("token"), function (result) {
      that.ruleFormRepair.org = result;
    });
    //根据物业信息查询出小区信息
    $.get(that.url + "/api-public/opevillage/findAllOpeVillage?access_token=" + localStorage.getItem("token"), function (result) {
      that.repairVillage = result.data;
    });
    //获取维修资金使用类型
    $.get(that.url + "/api-public/sysdict/list/?key=repair_type&access_token=" + localStorage.getItem("token"), function (result) {
      that.repairList = result;
    });
    //获取维修资对象
    $.get(that.url + "/api-base/facility/listAll/?parent_id=-1&access_token=" + localStorage.getItem("token"), function (result) {
      that.repairObject = result;
    });
    //获取维修规模
    $.get(that.url + "/api-public/sysdict/list/?key=repair_scale&access_token=" + localStorage.getItem("token"), function (result) {
      that.repairScale = result;
    });
    //获取分摊计算方式
    $.get(this.url + "/api-public/sysdict/list/?key=calcul_type&access_token=" + localStorage.getItem("token"), function (result) {
      that.calculList = result;
    });
  }
}
</script>

<style scoped>
.itemlabel  .el-form-item_label {
  color: #CF1111;
}

.stap3Button {
  display: flex;
  justify-content: center;
  padding: 15px 0;
}

.el-pagination {
  margin-top: 15px;
}

.content {
  width: 1200px;
  margin: 0 auto;
  padding: 20px 111px;
  min-height: 400px;
  background: #fff;
}
{
  color: #fff!important;
}
.title{
  position: absolute;
  top: 20px;
  left: 20px;
  font-size: 18px;
  font-weight: 400;
}
</style>
