<template>
    <div v-loading="loading" element-loading-text="分摊信息计算中,请稍后...">
        <!--        选择小区-->
        <div class="create_header">
                <div style=" float: left; width: 70px; font-weight: bold;color: rgba(64, 158, 254, 1)">当前小区：</div>
                <div style="font-weight: bold; color: rgba(64, 158, 254, 1)" v-for="(item,index) in villageData" :key="index"
                    :class="{active:item.checked}">
                    {{item.villageName}}
                </div>
        </div>
        <!--        选择楼幢-->
        <div class="create_header" v-show="towerData.length!=0">
            <ul>
                <h5>选择楼幢</h5>
                <li v-for="(item,index) in towerData" :key="index" @click="check(index,item.towerId,item)"
                    :class="{active:item.bon}">
                    {{item.towerNum}}
                </li>
            </ul>
            <div class="create_btn">
                <el-button type="primary" @click="checkALl" size="small">全选</el-button>
                <el-button type="info" @click="reverseAll" size="small">取消</el-button>
            </div>
        </div>
        <div class="content_main">

            <!-- 展示单元-->
            <div class="create_content">
                <ul v-show="unitData.length!=0">
                    <h5>选择单元</h5>
                    <div>
                        <li v-for="(item,index) in unitData" @click="checkHS(index)" :key="index"
                            :class="{active1:item.checked}">
                            <p>{{item.unitName}}</p>
                        </li>
                    </div>
                </ul>
                <div class="create_btn" v-show="unitData.length!=0">
                    <el-button type="primary" @click="unitCheckALl" size="small">全选</el-button>
                    <el-button type="info" @click="unitReverseAll" size="small">取消</el-button>
                </div>
                <ul v-show="houseData.length!=0">
                    <h5>选择户室</h5>
                    <div>
                        <li v-for="(item,index) in houseData" @click="tabHS(index)" :key="index"
                            :class="{active1:item.checked}">
                            <p>{{item.houseNum}}</p>
                            <p style="font-size:5px">{{item.coveredarea+"m²"}}</p>
                        </li>
                    </div>
                </ul>
                <div class="create_btn" v-show="houseData.length!=0">
                    <el-button type="primary" @click="houseCheckALl" size="small">全选</el-button>
                    <el-button type="info" @click="houseReverseAll" size="small">取消</el-button>
                    <!--                    <el-button type="success" @click="send()" size="small">确定</el-button>-->
                    <el-button type="success" @click="send()" size="small">分摊预览</el-button>
                </div>
            </div>
            <!--分摊信息预览-->
            <el-dialog title="分摊信息预览" :visible.sync="dialogTableVisible" width="80%"  append-to-body>
                <el-table :data="gridData" max-height="350"   v-loading="loading1" element-loading-text="分摊信息生成中,请稍后...">
                    <el-table-column property="villageName" label="小区名称"></el-table-column>
                    <el-table-column property="towerNum" label="楼幢号"></el-table-column>
                    <el-table-column property="unitName" label="单元名称"></el-table-column>
                    <el-table-column property="houseNum" label="户室号"></el-table-column>
                    <el-table-column property="coveredarea" label="户室面积"></el-table-column>
                    <el-table-column property="ownerName" label="业主姓名"></el-table-column>
                    <!--                    <el-table-column property="share" label="分摊方式"></el-table-column>-->
                    <el-table-column property="moneyCount" label="预算总金额"></el-table-column>
                    <el-table-column property="money" label="分摊金额"></el-table-column>
                </el-table>
                <div style="margin-top: 20px;text-align: center">
                    <el-button @click="qdft" type="primary">确定</el-button>
<!--                    <el-button @click="dcft" type="success">导出</el-button>-->
                    <el-button @click="qxft">取消</el-button>
                </div>

            </el-dialog>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'


    export default {
        name: "checkHouse",
        data() {
            return {
                url: this.$Config.base_server,
                form: {},

                villageData: [],//小区数据
                towerData: [],//楼幢数据
                unitData: [],//单元数据
                houseData: [],//户室数据

                towerIds: [],//选中的楼幢id 数组
                unitIds: [],//选中的单元Id数组
                dialogTableVisible: false,
                gridData: [],//生成的预览信息
                loading: false,
                loading1: false,
                shareId: '-1'
            }
        }, props: {
            checkHouseData: {
                type: Object,
                required: true
            }
        },
        mounted() {
            this.checkXiaoQu();
            this.checkVillageCommon();

        },
        methods: {
            // checkVillageItem(index, item) {//点击小区查楼撞单选
            //     //清空楼幢 单元 户室数据
            //     this.towerData.length = 0;
            //     this.unitData.length = 0;
            //     this.houseData.length = 0;
            //     this.villageData.forEachf((item, index) => {
            //         item.checked = false
            //     })
            //     this.villageData[index].checked = !this.villageData[index].checked;
            //     this.checkVillageCommon(item);
            //
            // },
            checkVillageCommon() {
                // let arr = [];//小区ids
                // arr.push(item.villageId);
                const that = this;
                const data = {
                    access_token: localStorage.getItem("token"),
                    villageIds:   that.checkHouseData.repairShare.villageId
                }
                $.ajax({
                    url: this.url + "/api-public/opetower/towerByVillageId",
                    data: data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        that.towerData = Object.values(res).flat(Infinity);
                    }
                })
            },
            checkXiaoQu() {
                const that = this;
                let tenementId = JSON.parse(localStorage.getItem("user")).id;
                const data = {
                    access_token: localStorage.getItem("token"),
                    villageId: that.checkHouseData.repairShare.villageId
                }
                $.ajax({
                    url: this.url + "/api-public/opevillage/findAllOpeVillage",
                    data: data,
                    contentType: "application/json",
                    type: "get",
                    success: function (res) {
                        if (res.code == 0) {
                            console.log(res.data)
                            that.villageData = res.data;
                        }
                    }
                })
            },
            houseCheckALl() {
                this.houseData.forEach((item, index) => {
                    item.checked = true;
                })
            },
            houseReverseAll() {

                this.houseData = [];

            },
            unitCheckALl() {//全选单元
                this.houseData.length = 0;
                this.unitData.forEach((item, index) => {
                    item.checked = true;
                })

                let arr = [];
                this.unitData.forEach((item, index) => {
                    arr.push(item.unitId);
                });

                const that = this;
                $.ajax({
                    url: that.url + "/api-public/findByUnitId/list?access_token=" + localStorage.getItem("token"),
                    data: JSON.stringify(arr),
                    type: 'post',
                    contentType: 'application/json',
                    success: function (res) {
                        that.houseData = Object.values(res).flat(Infinity);
                    }
                });
            },
            unitReverseAll() {//取消全选单元
                this.unitData.forEach((item, index) => {
                    item.checked = false;
                })
                this.houseData = [];
            },
            send() {

                //循环户室，把选中的选择
                let arr = [];
                this.houseData.forEach((item, index) => {
                    if (item.checked == true) {
                        arr.push(item.houseId)
                    }
                });
                if (arr.length == 0) {
                    this.$message({
                        type: 'error',
                        message: '请先选择需要参与分摊的户室'
                    });
                } else {
                    this.loading = true;
                    //将父组件传过来的字符串转换成对象
                    var parentData = this.checkHouseData;

                    //封装分摊数据
                    let data = {
                        "repairShare": {
                            "shareId": parentData.repairShare.shareId,//
                            "moneyCount": parentData.repairShare.moneyCount,
                            "repairId": parentData.repairShare.repairId
                        },
                        "houseIds": arr
                    }
                    const _this = this;
                    $.ajax({
                        url: _this.url + "/api-public/repairShareDetail/backViewRepairDetailNoSave?access_token=" + localStorage.getItem("token"),
                        type: 'post',
                        data: JSON.stringify(data),
                        contentType: 'application/json',
                        success: function (result) {
                            if (result.resp_code == 0) {
                                _this.loading = false;
                                //分摊成功
                                //弹出分摊信息预览的弹出框
                                _this.dialogTableVisible = true;
                                _this.gridData = result.datas;
                            }
                            if (result.resp_code == 1) {
                                _this.loading = false;
                                _this.$message({
                                    type: 'error',
                                    message: result.resp_msg
                                });
                            }
                        }
                    });
                }
            },
            tabHS(index) {//点击切换户室
                this.houseData[index].checked = !this.houseData[index].checked;
            },
            checkHS(index) {//根据单元查户室
                this.houseData.length = 0;
                this.unitData[index].checked = !this.unitData[index].checked;
                let arr = [];
                this.unitData.forEach((item, index) => {
                    if (item.checked == true) {
                        arr.push(item.unitId);
                    }
                });

                const that = this;
                $.ajax({
                    url: that.url + "/api-public/findByUnitId/list?access_token=" + localStorage.getItem("token"),
                    data: JSON.stringify(arr),
                    type: 'post',
                    contentType: 'application/json',
                    success: function (res) {
                        that.houseData = Object.values(res).flat(Infinity);
                    }
                });
            },
            checkALl() {//全选
                this.unitData.length = 0;
                this.houseData.length = 0;
                this.towerData.forEach((item, index) => {
                    item.bon = true;
                })
                //全选直接传this.towerData
                var _this = this;
                let towerIds = [];
                _this.towerData.forEach((item, index) => {
                    towerIds.push(item.towerId)
                });
                $.ajax({
                    url: this.url + "/api-public/opeunit/unitByTowerId?access_token=" + localStorage.getItem("token"),
                    data: JSON.stringify(towerIds),
                    type: 'post',
                    contentType: 'application/json',
                    success: function (res) {
                        _this.unitData = Object.values(res).flat(Infinity);
                    }
                });
            },
            reverseAll() {//取消全选
                this.towerData.forEach((item, index) => {
                    item.bon = false;
                });
                //取消传空
                this.unitData = [];
                this.houseData = [];
            },
            check(index, towerId, row) {
                this.unitData.length = 0;
                this.houseData.length = 0;
                this.towerData[index].bon = !this.towerData[index].bon;
                //将选中的towerId 放进towerIds数组中
                let arr = [];
                this.towerData.forEach((item, index) => {
                    if (item.bon == true) {
                        arr.push(item.towerId);
                    }
                });
                var _this = this;
                $.ajax({
                    url: this.url + "/api-public/opeunit/unitByTowerId?access_token=" + localStorage.getItem("token"),
                    data: JSON.stringify(arr),
                    type: 'post',
                    contentType: 'application/json',
                    success: function (res) {
                        _this.unitData = Object.values(res).flat(Infinity);
                    }
                });
            }, checkUnit(index, unitId, row) {
                this.unitData[index].bon = !this.unitData[index].bon;
                //将选中的towerId 放进towerIds数组中
                let arr = [];
                this.unitData.forEach((item, index) => {
                    if (item.bon == true) {
                        arr.push(item.unitId);
                    }
                });
            }, qdft() {

                //确定分摊
                //循环户室，把选中的选择
                this.loading1 = true;
                let arr = [];
                this.houseData.forEach((item, index) => {
                    if (item.checked == true) {
                        arr.push(item.houseId)
                    }
                });

                //将父组件传过来的字符串转换成对象
                var parentData = this.checkHouseData;
                //封装分摊数据
                let data = {
                    "repairShare": {
                        "shareId": parentData.repairShare.shareId,//
                        "moneyCount": parentData.repairShare.moneyCount,
                        "repairId": parentData.repairShare.repairId
                    },
                    "houseIds": arr,
                    "flag": ''
                }
                const _this = this;
                //判断是否是二次选择户室
                if (parentData.lpxzCount != 0) {
                    //二次弹出楼盘选择按钮
                    _this.$confirm('如果重新选择楼盘，原来的分摊信息将会被覆盖, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        data.flag = "DELETE";
                        $.ajax({
                            url: _this.url + "/api-public/repairShareDetail/save?access_token=" + localStorage.getItem("token"),
                            type: 'post',
                            data: JSON.stringify(data),
                            contentType: 'application/json',
                            success: function (result) {
                                if (result.resp_code == 0) {
                                    //关闭弹框
                                    _this.dialogTableVisible = false;
                                    _this.shareId = result.datas;
                                    _this.$emit("checkHouseData", result.datas);
                                    _this.loading1 = false;
                                }
                            }
                        });
                    }).catch(() => {
                        _this.dialogTableVisible = false;
                        _this.loading1 = false;
                        _this.$emit("checkHouseData", _this.shareId);
                    });
                } else {
                    $.ajax({
                        url: _this.url + "/api-public/repairShareDetail/save?access_token=" + localStorage.getItem("token"),
                        type: 'post',
                        data: JSON.stringify(data),
                        contentType: 'application/json',
                        success: function (result) {
                            if (result.resp_code == 0) {
                                //关闭弹框
                                _this.dialogTableVisible = false;
                                _this.shareId = result.datas;
                                _this.$emit("checkHouseData", result.datas);
                                _this.loading1 = false;
                            }
                        }
                    });
                }
            },
            // dcft(){
            //     var that = this;
            //     const  url=that.url + "/api-public/repairShareDetail/exportExcel?repairId=" + that.createrepairID+"&access_token=" + localStorage.getItem("token");
            //     location.href=url;
            // },
            qxft() {
                //取消分摊
                this.dialogTableVisible = false;
                this.loading1 = false;
                this.$emit("checkHouseData", this.shareId);
            }
        }
    }
</script>

<style scoped lang="scss">
    .content_main {
        padding-top: 20px;
    }

    .create_header > ul .active {
        background: rgba(64, 158, 254, 1);
        color: #fff;
    }

    .create_content > ul > div .active1 {
        background: rgba(64, 158, 254, 1);
        color: #fff;
    }


    .create_header {
        border-bottom: 1px dashed #e5e5e5;
        padding-bottom: 10px;
    }

    .create_header ul {
        margin-top: 15px;
    }

    .create_header ul li {
        cursor: pointer;
        background: #d9d9d9;
        text-align: center;
        padding: 5px 15px;
        border-radius: 5px;
        margin-right: 10px;
        margin-bottom: 10px;
        color: #666;
        display: inline-block;
    }

    .create_btn {
        margin: 15px 0;
        display: flex;
        justify-content: center;
    }

    .create_form {
        padding-left: 20px;
        margin-top: 10px;
    }

    .create_header ul {
        margin-bottom: 20px;
        max-height: 500px;
        overflow-y: scroll;
        padding: 10px;
        min-width: 300px;
        border-radius: 5px;
        border: 1px solid #999;

        h5 {
            margin-bottom: 10px;
        }
    }

    .create_content ul {
        margin-bottom: 20px;
        max-height: 500px;
        overflow-y: scroll;
        padding: 10px;
        min-width: 300px;
        border-radius: 5px;
        border: 1px solid #999;

        h5 {
            margin-bottom: 10px;
        }
    }

    .create_content ul li {
        cursor: pointer;
        overflow: hidden;
        background: #d9d9d9;
        padding: 5px 10px;
        color: #666;
        margin-right: 10px;
        display: inline-block;
    }

    .create_content ul li p {
        text-align: center;
        margin: 5px 0;
    }
</style>