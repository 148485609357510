<template>
    <div>
        <div class="tab_content">
            <el-form ref="ruleForm" :rules="rules" :model="ruleForm" label-width="120px" v-loading="loading"
                     element-loading-text="发起投票中，请稍后...">
                <el-form-item label="投票事项名称" prop="name">
                    <el-input placeholder="请输入投票事项名称" v-model="ruleForm.voteName" size="small"></el-input>
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="投票起始日期" prop="startTime">
                            <el-col :span="24">
                                <el-date-picker
                                        size="small"
                                        type="date"
                                        placeholder="选择日期"
                                        v-model="ruleForm.startTime"
                                        style="width: 100%;"
                                        :picker-options="pickerOptions0"
                                >

                                </el-date-picker>
                            </el-col>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="投票截至日期" prop="endTime">
                            <el-col :span="24">
                                <el-date-picker
                                        size="small"
                                        type="date"
                                        placeholder="选择日期"
                                        v-model="ruleForm.endTime"
                                        style="width: 100%;"
                                        :picker-options="pickerOptions1"
                                >
                                </el-date-picker>
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')" size="small">发起投票</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    export default {
        name: "vote",
        props: ["id"],
        data() {
            return {
                ruleForm: {
                    createRepairId: this.id,
                    voteName: '',
                    startTime: '',
                    endTime: '',
                },
                rules: {
                    voteName: [
                        {required: true, message: '请输入活动名称', trigger: 'blur'},
                    ],
                    startTime: [
                        {type: 'date', required: true, message: '请选择投票起始日期', trigger: 'change'}
                    ],
                    endTime: [
                        {type: 'date', required: true, message: '请选择投票截止日期', trigger: 'change'}
                    ]
                },
                url: this.$Config.base_server,
                loading: false,
                pickerOptions0: {
                    disabledDate: (time) => {
                        return time.getTime() <Date.now();
                    },

                },
                pickerOptions1: {
                    disabledDate: (time) => {
                        return time.getTime() < new Date(this.ruleForm.startTime).getTime();//减去一天的时间代表可以选择同一天;
                    }
                }
            }
        },
        methods: {
            submitForm(formName) {
                const _this = this;
                _this.loading = true;
                this.$refs[formName].validate((valid) => {
                    if (valid) {


                        this.$confirm('是否发起线上投票?', '提示', {
                            confirmButtonText: '是',
                            cancelButtonText: '否',
                            type: 'warning'
                        }).then(() => {
                            //存储投票信息
                            //线上投票
                            _this.ruleForm.lineState=0;
                            $.ajax({
                                url: _this.url + "/api-public/vote/save?access_token=" + localStorage.getItem("token"),
                                type: 'post',
                                data: JSON.stringify(_this.ruleForm),
                                contentType: 'application/json',
                                success: function (res) {
                                    _this.loading = false;
                                    console.log(res);
                                    if (res.resp_code == 0) {
                                        _this.$message({
                                            message: res.resp_msg,
                                            type: 'success',
                                            onClose: function () {
                                                //跳转到维修项目页面
                                                _this.$router.push("/repairItem");
                                            }
                                        });
                                    }
                                }
                            });
                        }).catch(() => {
                           //线下投票
                            //存储投票信息
                            //线上投票
                            _this.ruleForm.lineState=1;
                            $.ajax({
                                url: _this.url + "/api-public/vote/save?access_token=" + localStorage.getItem("token"),
                                type: 'post',
                                data: JSON.stringify(_this.ruleForm),
                                contentType: 'application/json',
                                success: function (res) {
                                    _this.loading = false;
                                    console.log(res);
                                    if (res.resp_code == 0) {
                                        _this.$message({
                                            message: res.resp_msg,
                                            type: 'success',
                                            onClose: function () {
                                                //跳转到维修项目页面
                                                _this.$router.push("/repairItem");
                                            }
                                        });
                                    }
                                }
                            });
                        });
                    } else {
                        _this.loading = false;
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>


    .el-form {
        width: 600px;
        margin: 0 auto;
    }
</style>